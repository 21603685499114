import NewDogForm from "../../Components/NewDogForm/NewDogForm";

export default function NewDogPage() {
    return(
        <>
        <main>
        <h1>Add a dog to the database</h1>
        <NewDogForm/>
        </main>
        </>
    )
}