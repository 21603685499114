
import NewBreederForm from "../../Components/NewBreederForm/NewBreederForm"

export default function NewBreederPage(){
    return(
        <>
        <main>
            <h1>Add A Breeder </h1>
        </main>
        <NewBreederForm/>
        </>
    )
}